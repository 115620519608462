import { createI18n } from 'vue-i18n';

const messages = {
    it: {
        // LOGIN
        forgot_password: 'Password dimenticata?',
        login: 'Accedi',
        // SIGNIN
        name: 'Nome',
        surname: 'Cognome',
        email: 'Email',
        password: 'Password',
        repeat_password: 'Ripeti Password',
        signin: 'Registrati',
        term_of_use: 'Accetto i termini d\'uso, la privacy policy e la cookie policy del servizio e acconsento al trattamento dei miei dati personali ai fini dell\'erogazione dello stesso e per ricevere comunicazioni',
        // LANDING PAGE
        intro: 'Bookly è la piattaforma per lo scambio di libri usati a prezzi convenienti.',
        no_account: 'Non hai un account?',
        how_work: 'Come funziona',
        how_work_desc: 'Bookly nasce dall\'idea di mettere in contatto gli studenti dando loro la possibilità di comprare o vendere i propri libri di testo.',
        title_para_1: 'Liberati dei libri che non usi più',
        para_1: 'Liberati dei libri che non usi più e guadagnaci: il prezzo di vendita lo stabilisci tu ed essendo il servizio gratuito guadagni il 100%.',
        title_para_2: 'Il portale dei libri a prezzi convenienti',
        para_2: 'Acquista libri in ottime condizioni a prezzi stracciati in totale sicurezza: l\'acquisto lo porti a termine di persona, quindi sei sempre libero di cambiare idea se il libro non soddisfa le tue aspettative.',
        title_para_3: 'Ci guadagni',
        para_3: 'L\'iscrizione è gratuita e puoi pubblicare tutti gli annunci che vuoi. Bookly non ti chiederà un centesimo. Inoltre il prezzo lo decidi tu e nessun altro.',
        title_para_4: 'Direttamente nella tua scuola',
        para_4: 'Comprare dei libri non è mai stato così facile: tutto ciò che ti serve è il codice ISBN. Bookly penserà al resto, mostrandoti i testi in vendita nella tua scuola o nella tua città.',
        title_para_5: 'Semplice e veloce',
        para_5: 'Pubblica un annuncio semplicemente selezionando il libro che intendi vendere e indicandone il prezzo: Bookly penserà ad inserire il resto delle informazioni automaticamente.',
        title_para_6: 'User friendly',
        para_6: 'Bookly è stato sviluppato per poter essere utilizzato da tutti e da qualsiasi dispositivo, così puoi acquistare libri direttamente dal tuo pc, smartphone o tablet.',
        // FOOTER
        follow_us: 'Resta aggiornato seguendoci sui social!',
      },
};

// Configura Vue I18n
const i18n = createI18n({
  locale: 'it', // Lingua predefinita
  messages,
});

export default i18n;