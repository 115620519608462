<template>
    <div class="login-logo">
        <router-link to="/">
            <img :src="require('@/assets/logo-home.png')" alt="Bookly logo" width="200">
        </router-link>
    </div>
    <div>
        <v-card
        class="mx-auto pa-12 pb-8 signin-positioning"
        elevation="8"
        max-width="448"
        rounded="lg"
        >
            <div class="text-subtitle-1 text-medium-emphasis d-flex"> {{$t('name')}} </div>

            <v-text-field
                density="compact"
                placeholder="Nome"
                prepend-inner-icon="mdi mdi-account"
                variant="outlined"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex"> {{$t('surname')}} </div>

            <v-text-field
                density="compact"
                placeholder="Cognome"
                prepend-inner-icon="mdi mdi-account"
                variant="outlined"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex"> {{$t('email')}} </div>

            <v-text-field
                density="compact"
                placeholder="Email"
                prepend-inner-icon="mdi-email-outline"
                variant="outlined"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                 {{$t('password')}} 
            </div>

            <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                density="compact"
                placeholder="Ripeti Password"
                prepend-inner-icon="mdi-lock-outline"
                variant="outlined"
                hint="Minimo 8 caratteri"
                name="input-10-1"
                @click:append="show1 = !show1"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                 {{$t('repeat_password')}} 
            </div>

            <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show2 ? 'text' : 'password'"
                density="compact"
                placeholder="Ripeti Password"
                prepend-inner-icon="mdi-lock-outline"
                variant="outlined"
                hint="Minimo 8 caratteri"
                name="input-10-2"
                @click:append="show2 = !show2"
            ></v-text-field>

            <v-checkbox
                v-model="checkbox"
                :rules="[v => !!v || 'Obbligatorio!']"
                :label="$t('term_of_use')"
                required
                class="text-justify"
            ></v-checkbox>

            <div class="mb-4 mt-4">
                <router-link class="text-decoration-none" to="/home">
                    <btn class="bg-primary-color login-btn">
                        {{$t('signin')}} 
                    </btn>
                </router-link>
            </div>
        </v-card>
    </div>
</template>

<script>
    export default {
        data () {
            return {
                show1: false,
                show2: true,
                password: 'Password',
                rules: {
                required: value => !!value || 'Required.',
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
                },
                checkbox: false,
            }
        },
    }
</script>

<style scoped>
.signin-positioning {
    margin-bottom: 100px;
}
</style>