<template>
  <div class="first-landing-section">
    <div class="index-logo">
      <img :src="require('@/assets/logo-home.png')" alt="Bookly logo" width="300">
    </div>
    <div class="intro">
      {{$t('intro')}}
    </div>
    <div class="sign-area">
      <router-link class="text-decoration-none" to="/login">
        <btn class="bg-primary-color login-btn">
          {{$t('login')}}
        </btn>
      </router-link>
      <div class="signin-spacing">
        {{$t('no_account')}}
          <router-link class="text-decoration-none" to="/signin">
            <a
            class="text-decoration-none primary-color"
            href="#"
            rel="noopener noreferrer"
            target="_blank"
            >
            {{$t('signin')}}.
            </a>
          </router-link>
      </div>
    </div>
  </div>
  <div class="explanation">
    <div class="primary-color explanation-title">
      <span class="mdi mdi-wrench"></span> {{$t('how_work')}}
    </div>
    <div class="text-black explanation-desc">
      {{$t('how_work_desc')}}
    </div>
    <div class="explanation-grid d-flex flex-wrap justify-center text-justify">
      <div class="explanation-para">
        <div class="explanation-para-title primary-color"><span class="mdi mdi-book-open-blank-variant"></span> {{$t('title_para_1')}}</div>
        <div class="explanation-para-desc text-black">{{$t('para_1')}}</div>
      </div>
      <div class="explanation-para">
        <div class="explanation-para-title primary-color"><span class="mdi mdi-cart-variant"></span> {{$t('title_para_2')}}</div>
        <div class="explanation-para-desc text-black ">{{$t('para_2')}}</div>
      </div>
      <div class="explanation-para">
        <div class="explanation-para-title primary-color"><span class="mdi mdi-credit-card"></span> {{$t('title_para_3')}}</div>
        <div class="explanation-para-desc text-black">{{$t('para_3')}}</div>
      </div>
      <div class="explanation-para">
        <div class="explanation-para-title primary-color"><span class="mdi mdi-map-marker"></span> {{$t('title_para_4')}}</div>
        <div class="explanation-para-desc text-black">{{$t('para_4')}}</div>
      </div>
      <div class="explanation-para">
        <div class="explanation-para-title primary-color"><span class="mdi mdi-flag-variant"></span> {{$t('title_para_5')}}</div>
        <div class="explanation-para-desc text-black">{{$t('para_5')}}</div>
      </div>
      <div class="explanation-para">
        <div class="explanation-para-title primary-color"><span class="mdi mdi-cellphone-link"></span> {{$t('title_para_6')}}</div>
        <div class="explanation-para-desc text-black">{{$t('para_6')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>

<style scoped>
.first-landing-section {
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url('@/assets/library.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
}

.index-logo {
  margin-bottom: 100px;
}

.intro {
  font-size: 30px;
  margin: 0px 100px 0px 100px;
  font-weight: bold;
}

.sign-area {
  margin-top: 100px;
}

.signin-spacing {
  margin-top: 20px;
}

.explanation {
  height: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
}

.explanation-title {
  font-size: 54px;
  font-weight: bold;
}

.explanation-desc {
  font-size: 18px;
  padding-bottom: 50px;
  margin: 0px 100px 0px 100px;
}

.explanation-grid {
  gap: 100px;
}

.explanation-para {
  width: 200px;
}

.explanation-para-title {
  font-size: 24px;
  font-weight: bold;
}

.explanation-para-desc {
  font-size: 18px;
}
</style>