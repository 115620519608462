<template>    
  <v-footer class="d-flex flex-column pa-0 bookly-footer">
    <div class="bg-primary-color text-white d-flex w-100 align-center px-4">
      <div>{{$t('follow_us')}}</div>

      <v-spacer></v-spacer>

      <v-btn
        v-for="icon in icons"
        :key="icon"
        :icon="icon"
        class="mx-4"
        size="small"
        variant="plain"
      ></v-btn>
    </div>

    <div class="px-4 py-2 bg-black text-center w-100">
      {{ new Date().getFullYear() }} — <strong>Bookly</strong>
    </div>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      icons: ['mdi-facebook', 'mdi-twitter', 'mdi-instagram'], // esempio di icone mdi
    };
  },
};
</script>

<style scoped>
.v-footer {
  flex: 0 !important;
}
</style>