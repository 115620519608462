<template>
    <div class="login-logo">
        <router-link to="/">
            <img :src="require('@/assets/logo-home.png')" alt="Bookly logo" width="200">
        </router-link>
    </div>
    <div>
        <v-card
        class="mx-auto pa-12 pb-8"
        elevation="8"
        max-width="448"
        rounded="lg"
        >
            <div class="text-subtitle-1 text-medium-emphasis d-flex">{{$t('email')}}</div>

            <v-text-field
                density="compact"
                placeholder="Email"
                prepend-inner-icon="mdi-email-outline"
                variant="outlined"
            ></v-text-field>

            <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                {{$t('password')}}

                <a
                class="text-caption text-decoration-none primary-color"
                href="#"
                rel="noopener noreferrer"
                target="_blank"
                >
                {{$t('forgot_password')}}</a>
            </div>

            <v-text-field
                :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible ? 'text' : 'password'"
                density="compact"
                placeholder="Password"
                prepend-inner-icon="mdi-lock-outline"
                variant="outlined"
                @click:append-inner="visible = !visible"
            ></v-text-field>

            <div class="mb-4 mt-4">
                <router-link class="text-decoration-none" to="/home">
                    <btn class="bg-primary-color login-btn">
                        {{$t('login')}}
                    </btn>
                </router-link>
            </div>

            <v-card-text class="text-center">
                <router-link class="text-decoration-none" to="/signin">
                    <a
                    class="text-decoration-none primary-color"
                    href="#"
                    rel="noopener noreferrer"
                    target="_blank"
                    >
                    {{$t('signin')}} <v-icon icon="mdi-chevron-right"></v-icon>
                    </a>
                </router-link>
            </v-card-text>
        </v-card>
    </div>
</template>

<style scoped>

</style>