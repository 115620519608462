<template>
  <div id="app">
    <div class="content-wrapper">
      <router-view/>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
import FooterComponent from './components/FooterComponent.vue';

export default {
  components: {
    FooterComponent,
  },
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: 'Mangoli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.bookly-footer {
  flex-shrink: 0;
  padding: 1rem; /* Spaziatura del footer */
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* Evita padding e margini non necessari */
  margin: 0;
  padding: 0;
}

/* LOGIN SIGNIN */
.login-logo {
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  background-image: url('@/assets/library.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
}

/* BUTTONS */

.login-btn {
  color: white;
  font-weight: bold;
  padding: 20px;
  margin-bottom: 20px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-btn:hover {
  background-color: #dd7200;
}

/* CUSTOM CSS PROP */

.primary-color {
  color: #ff8400;
}

.bg-primary-color {
  background-color: #ff8400;
}

.bg-secondary-color {
  background-color: #dddddd;
}

.text-black {
  color: black !important;
}
</style>